<template>
	<main-container :showHeader="false">
		<el-card>
			<advanced-search-bar>
				<template>
					<form-item label="环境:">
						<el-select v-model="queryForm.environment" size="mini" clearable>
							<el-option v-for="item in envs" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" icon="el-icon-search" @click="searchEvent">查 询</el-button>
					</form-item>
					<form-item>
						<el-button type="primary" size="mini" @click="visible.add=true">添加任务</el-button>
					</form-item>
				</template>
				<template #advanced>
					<form-item label="任务类型:">
						<el-select v-model="queryForm.type" size="mini" clearable>
							<el-option v-for="item in taskTypes" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="任务状态:">
						<el-select v-model="queryForm.status" size="mini" clearable>
							<el-option v-for="item in taskStates" :label="item.value" :value="item.key"></el-option>
						</el-select>
					</form-item>
					<form-item label="Feed 名称:">
						<el-input v-model.trim="queryForm.name" placeholder="请输入正则表达式" size="mini">
							<el-popover
								slot="append"
							    placement="bottom"
								width="200"
							    trigger="hover">
								<small>{{regexTip}}</small>
								<el-button type="text" @click="jumpToRegex()" size="mini">了解更多</el-button>
								<el-button slot="reference" @click="queryForm.name='[a-z]{3}_fb_ca_.*'" size="mini">复制例子</el-button>
							</el-popover>
						</el-input>
					</form-item>
					<form-item label="任务设定时间">
						<el-date-picker v-model="queryForm.date" type="daterange" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width:250px;" size="mini"
							:clearable="false">
						</el-date-picker>
					</form-item>
				</template>
			</advanced-search-bar>
			<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
			<alert v-if="multipleSelection.length > 0" class="my-2">
				已选择<span class="text-primary d-inline-block mx-1">{{multipleSelection.length}}个。</span>
				<el-button type="text" size="mini" @click="stopFeeds">批量中止</el-button>
			</alert>
			<el-table height="auto" ref="multipleTable" :data="tableData" @selection-change="handleSelectionChange"
				@select="pinSelect">
				<el-table-column type="selection" width="55" :selectable="rowSelectable">
				</el-table-column>
				<el-table-column label="环境" min-width="120">
					<template slot-scope="scope">
						{{scope.row.environment|value(envs)}}
					</template>
				</el-table-column>
				<el-table-column label="任务类型" min-width="120">
					<template slot-scope="scope">
						{{scope.row.type|value(taskTypes)}}
					</template>
				</el-table-column>
				<el-table-column label="Feed名称" prop="name" min-width="150">
				</el-table-column>
				<el-table-column label="任务设定时间" min-width="200">
					<template slot-scope="scope">
						{{scope.row.createdAt|formatDate('YYYY/MM/DD HH:mm:ss')}}
					</template>
				</el-table-column>
				<el-table-column label="任务开始时间" min-width="200">
					<template slot-scope="scope">
						<div v-if="scope.row.executedAt">{{scope.row.executedAt|formatDate('YYYY/MM/DD HH:mm:ss')}}</div>
						<div v-else>-</div>
					</template>
				</el-table-column>
				<el-table-column label="任务进度" min-width="200">
					<template slot-scope="scope">
						<template v-if="scope.row.status === 'QUEUED'">
							<el-progress :percentage="0" status="success" class="task-in-queue"></el-progress>
						</template>
						<template v-else-if="scope.row.status === 'IN_PROGRESS'">
							<el-progress :percentage="scope.row.progress"></el-progress>
						</template>
						<template v-else>
							<el-progress :percentage="scope.row.progress || 0" :status="scope.row.status|status(taskStates)">
							</el-progress>
						</template>
					</template>
				</el-table-column>
				<el-table-column label="任务耗时" min-width="150">
					<template slot-scope="scope">
						{{scope.row.timeCost|costTime}}
					</template>
				</el-table-column>
				<el-table-column label="文件大小" min-width="150">
					<template slot-scope="scope">
						{{scope.row.feedFileSize|fileSize}}
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="150">
					<template slot-scope="scope">
						<el-button v-if="scope.row.status === 'QUEUED' || scope.row.status === 'IN_PROGRESS'" type="text" class="text-danger" @click="stopTask(scope.row)" size="mini">中止</el-button>
						<el-button v-if="scope.row.status === 'SUCCESS'" type="text" @click="downloadTask(scope.row)" size="mini">下载</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
				@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
				:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
			</el-pagination>
		</el-card>
		<el-dialog title="添加任务" :visible.sync="visible.add" width="500px">
			<el-form ref="form" :model="form" label-width="80px" :rules="rules" :hide-required-asterisk="true">
				<el-form-item label="环境" prop="environment">
					<el-select v-model="form.environment" class="w-100">
						<el-option v-for="item in envs" :label="item.value" :value="item.key"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="Feed 名称" prop="feedExpression">
					<el-input v-model.trim="form.feedExpression" placeholder="请输入正则表达式">
						<el-popover
							slot="append"
						    placement="bottom"
							width="200"
						    trigger="hover">
							<small>{{regexTip}}</small>
							<el-button type="text" @click="jumpToRegex()" size="mini">了解更多</el-button>
							<el-button slot="reference" @click="form.feedExpression='[a-z]{3}_fb_ca_.*'">复制例子</el-button>
						</el-popover>
					</el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.add = false">取 消</el-button>
				<el-button type="primary" @click="addTask">确 定</el-button>
			</div>
		</el-dialog>
	</main-container>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	import Common from '@/common/mixins/common.js'
	import Page from '@/common/mixins/page.js'
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	export default {
		mixins: [Common, Page, MultipleTableSelection],
		inject: ['adminLayout'],
		data() {
			return {
				queryForm: {
					environment: '',
					type: '',
					status: '',
					name: '',
					date: [
						Moment().add(-6, 'days').format('YYYY-MM-DD'),
						Moment().endOf('day').format('YYYY-MM-DD')
					]
				},
				q: {
					createdMinStr: Moment().add(-6, 'days').format('YYYY-MM-DD'),
					createdMaxStr: Moment().endOf('day').format('YYYY-MM-DD')
				},
				tableData: [],
				visible: {
					add: false
				},
				form: {
					environment: '',
					feedExpression: ''
				},
				rules: {
					environment: [{
						required: true,
						message: '环境不能为空'
					}],
					feedExpression: [{
						required: true,
						message: 'Feed名称不能为空'
					}]
				},
				regexTip: '正则表达式(regular expression)描述了一种字符串匹配的模式（pattern），可以用来检查一个串是否含有某种子串、将匹配的子串替换或者从某个串中取出符合某个条件的子串等',
				namespace: 'feeds-queue'
			}
		},
		computed: {
			...mapGetters({
				envs: 'feeds-queue/envs',
				taskTypes: 'feeds-queue/taskTypes',
				taskStates: 'feeds-queue/taskStates'
			})
		},
		filters: {
			costTime(value) {
				var formatText = ''
				const day = Math.floor(value / (3600 * 24 * 1000))
				if (day > 0) {
					formatText = `${day}day`
				}
				var leftTime = value % (3600 * 24 * 1000)
				const hour = Math.floor(leftTime / (3600 * 1000))
				if (hour > 0) {
					formatText = `${formatText}${hour}hour`
				}
				leftTime = leftTime % (3600 * 1000)
				const minute = Math.floor(leftTime / (60 * 1000))
				if (minute > 0) {
					formatText = `${formatText}${minute}min`
				}
				leftTime = leftTime % (60 * 1000)
				const second = Math.floor(leftTime / 1000)
				if (second > 0) {
					formatText = `${formatText}${second}s`
				}
				if (formatText === '' && value > 0) {
					return `${value}ms`
				}
				return formatText
			},
			fileSize(value) {
				var formatText = ''
				var m = value / (1024 * 1024)
				if (m > 1) {
					return `${m.toFixed(1)}MB`
				}
				var k = value / 1024
				if (k > 1) {
					return `${k.toFixed(1)}KB`
				}
				if (!value) {
					return ''
				}
				return `${value}B`
			},
			status(key, list) {
				const item = list.find(v => v.key === key)
				return item && item.status
			}
		},
		watch: {
			'visible.add'(newVal, oldVal) {
				if (newVal) {
					this.$nextTick(() => {
						this.$refs.form.clearValidate()
					})
				} else {
					this.form = {
						env: '',
						name: ''
					}
				}
			}
		},
		methods: {
			searchEvent() {
				this.q = {
					...this.queryForm,
					createdMinStr: this.queryForm.date[0],
					createdMaxStr: this.queryForm.date[1]
				}
				this.page.current = 1
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.$store.dispatch(`${this.namespace}/getList`, {
					params: {
						...this.page,
						...this.q
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			addTask() {
				this.$refs.form.validate(v => {
					if (v) {
						this.adminLayout.showLoading(true)
						this.$store.dispatch(`${this.namespace}/newItem`, {...this.form})
						.then(res => {
							this.adminLayout.hideLoading()
							this.visible.add = false
							this.$showSuccess('任务已添加')
							this.getList()
						})
						.catch(err => {
							this.adminLayout.hideLoading()
							this.$showErrMsg(err)
						})
					}
				})
			},
			rowSelectable(row, index) {
				return row.status === 'QUEUED' || row.status === 'IN_PROGRESS'
			},
			stopTask(row) {
				this.$confirm('此操作将中止当前任务, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.adminLayout.showLoading()
					this.$store.dispatch(`${this.namespace}/deactiveItem`, row.id)
					.then(res => {
						this.getList()
					})
					.catch(err => {
						this.$showErrMsg(err)
						this.adminLayout.hideLoading()
					})
				})
			},
			downloadTask(row) {
				this.$store.commit('download-manager/addTask', {
					action: `${this.namespace}/downloadFeed`,
					payload: row.id,
					title: row.name,
					dispatch: this.$store.dispatch,
					requestHandler() {
						return arguments[0]
					},
					responseType: 'blob'
				})
			},
			stopFeeds() {
				this.$confirm('此操作将批量中止队列中或正在执行的任务, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					const ids = this.multipleSelection.map(v => v.id)
					this.adminLayout.showLoading()
					this.$store.dispatch(`${this.namespace}/deactiveItems`, ids)
					.then(res => {
						this.getList()
					})
					.catch(err => {
						this.adminLayout.hideLoading()
					})
				})
			},
			jumpToRegex() {
				window.open('https://regex101.com')
			}
		}
	}
</script>

<style lang="scss">
	.el-progress.task-in-queue.is-success {
		.el-progress__text {
			color: #ddd;
			i:before {
				content: '\e71f';
			}
		}
	}
</style>
